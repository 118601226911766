import Vue from 'vue'
import EventBus from '_shared/EventBus'
// import store from '../store';


const plugin = {
    install(vue, store) {
        const signalR = require('@microsoft/signalr')

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_ID4Server + "/hubs/chathub", { accessTokenFactory: () => store.getters['account/user'].access_token })
            .configureLogging(signalR.LogLevel.Warning)
            .withAutomaticReconnect()
            .build();

        connection.on("RecieveChatRoom", async (chatroom) => {
            store.commit(`chat-${chatroom.eventId}-${chatroom.title}/SET_Chatroom`, chatroom)
            await store.dispatch(`chat-${chatroom.eventId}-${chatroom.title}/getMessages`, chatroom);
        });

        connection.on("RecieveMessage", (chatmessage) => {            
            store.commit(`chat-${chatmessage.eventId}-${chatmessage.chatRoom}/ADD_Message`, chatmessage);
        });
        connection.on("Update_Message", (chatmessage) => {            
            store.commit(`chat-${chatmessage.eventId}-${chatmessage.chatRoom}/UPD_Message`, chatmessage);
        });

        connection.on("clear", (chatroom) => {
            if (store.hasModule(`chat-${chatroom.eventId}-${chatroom.title}`)) {
                store.unregisterModule(`chat-${chatroom.eventId}-${chatroom.title}`)
                EventBus.$emit('chatroom_init');
            }
        });

        const chatFunctions = {
            start: async () => {
                try {
                    if (connection.state == signalR.HubConnectionState.Disconnected) {
                        await connection.start();  
                        console.log("Chat hub start")                      
                    } 

                } catch (err) {
                    console.log(err);
                    setTimeout(chatFunctions.start, 5000);
                }
            },
            stop: async () => {
                await connection.stop();
                console.log("Chat hub stop")
            },
            JoinChatRoom: (roomName, eventid) => {
                //console.log("join: " + eventid)
                connection.invoke('JoinChatRoom', roomName, store.state.account.user.profile.sub, eventid)
            },
            sendMessage: (msg, chatroom, aliasUserId, eventid) => {
                if (aliasUserId != undefined)
                    connection.invoke('sendMessage', msg, aliasUserId, store.getters[`chat-${eventid}-${chatroom}/GET_Chatroom`], eventid)
                else
                    connection.invoke('sendMessage', msg, store.state.account.user.profile.sub, store.getters[`chat-${eventid}-${chatroom}/GET_Chatroom`], eventid)
            },
            voteForMessage:(userId, messageId, vote,chatroom)=>{                
                connection.invoke('voteForMessage',userId,messageId,vote,chatroom);
            }
        };



        Vue.chathub = chatFunctions
        Vue.prototype.$chathub = chatFunctions

        window.chathub = chatFunctions;
    }
}
// Vue.use(plugin)
export default plugin;

<template>
  <v-responsive :aspect-ratio="16 / 9">
    <iframe      
      :src="link"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
      style="width: 100%; height: 100%"
      class="d-flex"
    ></iframe>
  </v-responsive>
</template>

<script>
import EventBus from "_shared/EventBus";

export default {
  data: () => ({
    link: 'about:blank',
  }),
  async beforeCreate() {
    this.link = (await this.$axios.get(`/api/webapp/config?clientid=${this.$store.state.client_id}&key=link.vimeo`)).data.value;
  },
  created() {
    EventBus.$on("update_config", (config) => {
      
      if (config.key === "link.vimeo") {
        this.link = config.value;
      }
    });
  },
  beforeDestroy()
  {
    EventBus.$off("update_config");
  }  
};
</script>

<style>
</style>
import Vue from 'vue'
// import EventBus from '_shared/EventBus'
import store_userwatcher from './store.js'

const plugin = {
    install(vue, store) {

        const signalR = require('@microsoft/signalr')

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_ID4Server + "/hubs/userwatcher"
                , { accessTokenFactory: () => store.getters['account/user']?.access_token })
            .configureLogging(signalR.LogLevel.Warning)
            .withAutomaticReconnect()
            .build();

        /*----*/
        connection.onreconnecting(() => {
            if (document.visibilityState == 'hidden') {
                connection.stop();
            }
        });

        connection.onclose(() => {
            console.log('Hub closed')
        });
        /*----*/

        connection.on("useradded", async (user) => {
            store.commit('userwatcher/ADD_User',user)
        });

        connection.on("userremoved", async (userId) => { 
            store.commit('userwatcher/REMOVE_User',userId)
        });

        const hubFunctions = {
            start: async () => {
                try {

                    if (connection.state == signalR.HubConnectionState.Disconnected) {
                        if(store.client_id=="operator.client")
                        {
                            store.commit('userwatcher/SET_User', []);     
                            await store.dispatch('userwatcher/loadUsers');
                        }                       
                        await connection.start();                        
                        console.log("watcher - New SignalR Connected.");
                    } else
                        console.log("watcher - SignalR Connected.");
                } catch (err) {
                    console.log(err);                    
                    setTimeout(hubFunctions.start, 5000);
                    return;
                }                                
            },
            stop: () => {
                connection.stop();
            },

        };


        Vue.userwatcher = hubFunctions
        Vue.prototype.$userwatcher = hubFunctions
        window.userwatcher = hubFunctions;

        if (!store.hasModule('userwatcher')) {
            store.registerModule('userwatcher', store_userwatcher);
        }

        document.addEventListener("visibilitychange", handleVisibilityChange, false);

        var timeout = undefined;
        if (timeout != undefined)
            clearTimeout(timeout);

        async function handleVisibilityChange() {
            
            if (document.visibilityState == 'hidden' && store.client_id!="operator.client") {
                timeout = setTimeout(() =>
                    window.userwatcher.stop()
                     , 1000 * 10);
            }
            if (document.visibilityState == 'visible') {
                if (timeout != undefined)
                    clearTimeout(timeout);

                if (store.getters['account/user'])
                    await window.userwatcher.start();
            }
        }
    }
}

export default plugin;
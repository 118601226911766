<template>
  <v-snackbar v-model="snackbar" :timeout="20000">
    <p>{{ txtcookie }}</p>
    <template v-slot:action="{ attrs }">
      <v-btn color=primary v-bind="attrs" @click="snackbar = false">
        {{$t('consent.close')}}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({    
    snackbar: true,
  }),
  computed:{
    txtcookie(){
      return this.$i18n.t('consent.text');
    }
  },
  created() {    
    this.snackbar = !window.sessionStorage.getItem("cc");
  },
  watch: {
    snackbar: {
      handler(val) {
        if (!val) {
          window.sessionStorage.setItem("cc", true);
        }
      },
    },
  },
};
</script>

<style>
</style>
<template>
  <iframe
    :src="setUser(link)"
    height="100%"
    width="100%"
    frameborder="0"
    :style="$vuetify.breakpoint.mobile ? 'min-height: 100vh' : ''"
  >
  </iframe>
</template>

<script>
import EventBus from "_shared/EventBus";

export default {
  data: () => ({
    link: "about:blank",
  }),
  methods: {
    setUser(link) {
      console.log(link + "?user_name=" + this.$store.getters["account/username"])
      if (this.$store.getters["account/user"] != undefined)
        return link + "?user_name=" + this.$store.getters["account/username"];
      else return link;
    },
  },
  async beforeCreate() {
    this.link = (
      await this.$axios.get(
        `/api/webapp/config?clientid=${this.$store.state.client_id}&key=link.slido`
      )
    ).data.value;
  },
  created() {
    EventBus.$on("update_config", (config) => {
      
      if (config.key === "link.slido") {
        this.link = config.value;
      }
    });
  },
  beforeDestroy() {
    EventBus.$off("update_config");
  },
};
</script>

<style>
</style>
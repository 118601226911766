const store = {
    namespaced: true,
    state: {
        currentUsers: []
    },
    getters: {
        currentUsers(state) {
            return state.currentUsers;
        },
        UserCount(state) {            
            if(state.currentUsers)
            return state.currentUsers.length;
            else
            return '0' //Badge show 0
        }
    },
    mutations: {
        SET_User(state, payload) {
            state.currentUsers = payload;
        },
        ADD_User(state, payload) {
            state.currentUsers.push(payload);
        },
        REMOVE_User(state, payload) {
            var user = state.currentUsers.find((s) => s.id === payload);            
            if (user != undefined) {
                state.currentUsers.splice(state.currentUsers.indexOf(user), 1);
            }
        }
    },
    actions: {
        async loadUsers({ commit, state }) {            
            if (state.currentUsers.length == 0) {
                let users = (await window.axios.get(`/api/UserStats/currentUsers`)).data;
                commit('SET_User', users);                
            }
            return state.currentUsers;
        }
    }
}

export default store;
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      variations: true
    },
    dark: false,
    themes: {
      light: {
        appshell:'#fff',
        primary: '#ff0000',
        secondary: '#777',
        accent: '#ff0000',
        error: '#FF0000',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        tertiary: '#ebebeb',
        footer: '#fff',
        footerInvers: '#000',
        anchor:'#000'
      },
      dark: {
        appshell:'#000',
        primary: '#cc092f',
        secondary: '#1a1a1a',
        accent: '#cc092f',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        tertiary: '#272727',
        footer: '#fff',
        footerInvers: '#000',
      },
    },
  },
  lang: {
    locales: { de },
    current: 'de',
  },
});

/*eslint-disable no-unused-vars*/
import { mgr } from '.'
import { router } from '.'
import { store } from '.'
import Error from './Error'


export const routesAccount = [
    {
        path: '/account',
        children:
            [
                {
                    path: 'silentrenew',
                    name: 'Silentrenew',
                    beforeEnter: async (to, from, next) => {
                        // console.log("Silentrenew")
                        // console.log(to)

                        try {
                            await mgr.signinSilentCallback();
                            return;
                        } catch (e) {
                            console.log("Error\n" + e);
                            if (e.message == 'Unauthorized') {
                                store.commit('account/SET_ErrorMsg', 'Unauthorized')
                                router.push({ name: 'Error' }).catch(() => { });
                            }
                        }
                    },
                },
                {
                    path: 'callback',
                    name: 'Callback',
                    beforeEnter: async (to, from, next) => {
                        //console.log("callback")
                        // console.log(to)                        

                        try {
                            var result = await mgr.signinRedirectCallback();

                            var returnToUrl = "/";
                            if (result.state !== undefined) {
                                returnToUrl = result.state;
                            }

                            let eventId = window.sessionStorage.getItem('eventId');
                            if (eventId)
                                window.location.replace(`${window.location.protocol}//${window.location.host}` + `/${eventId}`)
                            else
                                window.location.replace(returnToUrl)

                            return;
                        } catch (e) {
                            console.log("Error\n" + e);
                            if (e.message == 'Unauthorized') {
                                store.commit('account/SET_ErrorMsg', 'Unauthorized')
                                router.push({ name: 'Error' }).catch(() => { });
                            }
                        }
                    },
                },
            ]
    },
    {
        path: '/error',
        name: 'Error',
        component: Error,

    },
]






export default {}
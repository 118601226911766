<template>
  <div class="d-flex flex-column align-self-start div-100">
    <v-card flat class="ma-1">
      <v-textarea
        ref="textarea"
        placeholder="Hier können Sie Ihre individuelle Frage stellen"
        class="px-4 py-4"
        rows="2"
        row-height="30"
        auto-grow
        v-model="question"        
        append-outer-icon="mdi-send"        
        @click:append-outer="postNewQuestion"
        v-on:blur="postNewQuestion"
        v-on:keydown.enter.exact="$event.target.blur()"
        v-on:keyup.enter.alt.exact="addLineBreak()"         
      ></v-textarea>
      <!-- <v-card-actions class="align-end">
        <v-btn color="primary" icon><v-icon class="ml-1">mdi-send</v-icon></v-btn>
      </v-card-actions> -->
      <v-divider/>
    </v-card>

    <v-tabs class="mt-3" grow active-class="activeTab">
      <v-tab href="#unanswered">Open</v-tab>
      <v-tab href="#answered">Answered</v-tab>

      <v-tab-item id="unanswered">
        <v-item-group>
          <transition-group name="flip-list" tag="div">
            <v-item v-for="item in getUnAnswered" v-bind:key="item.question.id">
              <QnAItem v-bind:item="item" />
            </v-item>
          </transition-group>
        </v-item-group>
      </v-tab-item>

      <v-tab-item id="answered">
        <v-item-group>
          <transition-group name="flip-list" tag="div">
            <v-item v-for="item in getAnswered" v-bind:key="item.question.id">
              <QnAItem v-bind:item="item" />
            </v-item>
          </transition-group>
        </v-item-group>
      </v-tab-item>
    </v-tabs>

    <!-- <v-btn fab icon class="v-btn--example mr-6 mb-6" color="primary"><v-icon>mdi-plus</v-icon></v-btn> -->
  </div>
</template>

<script>
import QnAItem from "./QnAItem";

export default {
  components: {
    QnAItem,
  },
  data: () => ({
    question: "",
  }),
  computed: {
    getAnswered() {
      return this.$store.getters["qna/questions"].filter(
        (s) => s.question.isAnswered == true
      );
    },
    getUnAnswered() {
      return this.$store.getters["qna/questions"].filter(
        (s) => s.question.isAnswered == false
      );
    },
  },
  mounted() {
    this.getQuestions();
  },
  methods: {
    addLineBreak()
    {
      this.question+='\n'
    },
    async getQuestions() {
      await this.$store.dispatch("qna/getQuestions");
    },
    async postNewQuestion() {
      
      this.question=this.question.replace(/^\s+|\s+$/g, '');

      if (this.question.trim().length > 0) {
        this.$qnahub.askQuestion({ question: this.question });

        this.question = "";
        this.$refs.textarea.blur();
      }

      // var result=await this.$axios.post('api/qna/newQuestion', {question:'Frage 1'});
      // var data = (await result.data)

      // console.log(data);
    },
  },
};
</script>

<style>
.flip-list-move {
  transition: transform 1s;
}
.v-btn--example {
  bottom: 0;
  right: 0;
  position: absolute;
}
.activeTab
{
  font-family: Bold;
  font-weight: 100;  
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import {storeEvent} from '_shared/Event/store'
import {footer} from '_shared/Footer/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    client_id: 'webapp.client',  
    coloredHeader:['Live', 'WaitingRoom', 'Mediathek']  
  },
  modules:{
    event:storeEvent,
    footer
  }
});
<template>
  <v-container>
    <v-row>
      <v-col cols="auto">
        <v-card flat dark color="transparent" >
          <div>
            <a :href="$t('footer.credits.link')" target="_blank">
                <img src="../assets/logo.svg" height="36" />
            </a>
          </div>
          <v-card-subtitle class="px-0 white--text">
            <p v-html="$t('footer.credits.name')" />
            <p v-html="$t('footer.credits.adress')" />

            <div>
              <span><v-icon class="mr-2">mdi-phone</v-icon></span>
              <span v-html="$t('footer.credits.phone')"></span>
            </div>
            <div>
              <span><v-icon class="mr-2">mdi-fax</v-icon></span>
              <span v-html="$t('footer.credits.fax')"></span>
            </div>
            <div>
              <span><v-icon class="mr-2">mdi-email</v-icon></span>
              <a :href="'mailto:' + $t('footer.credits.email')"
                ><span v-html="$t('footer.credits.email')"></span
              ></a>
            </div>

          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col> </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-card flat dark color="transparent">
          <v-card-title class="white--text">
            <p v-html="$t('footer.information')"></p>
          </v-card-title>
          <v-card-subtitle class="white--text">            
            <Links :useColumn="true" />
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Links from "./components/Links";

export default {
  components: {
    Links,
  },
};
</script>

<style lang="scss" scoped>

</style>
import Vue from 'vue'
import { storeNotification } from './store'
import EventBus from '_shared/EventBus'

const plugin = {
    install(vue, store) {

        const signalR = require('@microsoft/signalr')
        
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_ID4Server + "/hubs/notificationhub")
            //, { accessTokenFactory: () => store.getters['account/user'].access_token })   
            .configureLogging(signalR.LogLevel.Warning)
            .withAutomaticReconnect()
            .build();        

        // connection.onreconnecting(error => {
        //     ///TODO show Dialog Box
        //     console.log('reconnecting: ' + error)
        //     console.log('Visibility'+ document.visibilityState);
        //     if(connection.state === signalR.HubConnectionState.Reconnecting && document.visibilityState=='hidden')
        //     {
        //         connection.stop();
        //     }
        // });
        // connection.onreconnected(async error => {
        //     console.log('reconnected: ' + error)
        //     console.log('Visibility'+ document.visibilityState);
        //     // if (connection.state == signalR.HubConnectionState.Connected)
        //     //     await hubFunctions.checkNotifications()
        // });
        // connection.onclose(() => {
        //     console.log('Hub closed')
        // });

        /*--------------------------*/

        
        connection.on("update_config", (webappConfiguration) => {
            EventBus.$emit('update_config', (webappConfiguration));
        });

        connection.on("receive_EventItemState", (eventStateItem) => {               
            if (store.state.event.event_id === eventStateItem.eventid)
                store.commit('notification/SET_EventStateItem', eventStateItem);
        });

        /*--------------------------*/

        const hubFunctions = {
            start: async () => {
                try {
                    if (connection.state == signalR.HubConnectionState.Disconnected) {
                        {
                            await connection.start();                            
                            console.log("Notification - New SignalR Connected.");
                        }
                    } else
                        console.log("Notification - SignalR Connected.");

                } catch (err) {
                    console.log(err);
                    connection.stop();
                    setTimeout(hubFunctions.start, 5000);
                }
            },
            stop: () => {
                connection.stop();
            },

            logIn: () => {
                connection.invoke('userLogIn');
            },
            getEventItemState: async (eventid) => {
                console.log("Check Event State.");                
                return await connection.invoke('getEventItemState', eventid);
            },
            timeout: async (ms) => {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        };



        Vue.notification = hubFunctions
        Vue.prototype.$notification = hubFunctions
        window.notification = hubFunctions;


        if (!store.hasModule('notification')) {
            store.registerModule('notification', storeNotification);
        }

        hubFunctions.start();
    }
}

export default plugin;

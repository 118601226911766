<template>
  <div class="home div-100" ref="container">
    <v-img
      :src="require('_shared/assets/bg.svg')"
      height="calc(100vh)"
      min-height="768"
      style="top: -48px"
      transition="fade"
    >
      <div class="d-flex flex-column align-center div-100 justify-center px-16">
        <h1 class="black--text event-title pb-3" v-html="$t('page.title')"></h1>
        <h1
          class="black--text event-subtitle"
          v-html="$t('page.subtitle')"
        ></h1>

        <h1 class="black--text event-subtitle" v-html="$t('page.date')"></h1>
        <RegisterOrLogin />
        <h1 class="mt-8 event-support" v-html="$t('page.register')"></h1>
        <v-btn
          class="mx-auto mt-16"
          color="primary"
          @click="dialog = !dialog"
          >{{ $t("home.btn_tech") }}</v-btn
        >
      </div>
    </v-img>

    <div class="div-100x d-flex flex-column">
      <LandingPageDescription class="mb-8" :center="true" />
    </div>
    <preview-video-popup
      :dialog.sync="dialog"
      :link="link"
    ></preview-video-popup>
  </div>
</template>

<script>
/*eslint-disable vue/no-unused-components*/

import LandingPageDescription from "../components/LandingPage/Description";
import PreviewVideoPopup from "./components/PreviewVideoPopup.vue";
import RegisterOrLogin from "./components/RegisterOrLogin";

export default {
  name: "Home",
  components: {
    LandingPageDescription,
    RegisterOrLogin,
    PreviewVideoPopup,
  },
  data: () => ({
    EventItems: [],
    dialog: false,
    link: "about:blank",
  }),
  async beforeCreate() {
    this.EventItems = await this.$store.dispatch("getEventItems");
    this.link = (
      await this.$axios.get(
        `/api/webapp/config?clientid=${this.$store.state.client_id}&key=link.vimeo.teaser`
      )
    ).data.value;
  }, 
};
</script>

<style lang="scss" scoped>
::v-deep a {
  
  color: var(--v-primary-base)!important;
}

::v-deep a,
a:link,
a:visited,
a:hover,
a:active {
  color: var(--v-primary-base);
}

.event-title {
  font-family: "Head";
  font-size: 9.3rem;
  line-height: 1;
  padding-bottom: 48px;
}
.event-subtitle {
  font-family: "Thin";
  font-size: 1.5rem;
}
.event-support {
  font-family: "Regular";
  font-size: 1.8rem;
}
.event-title,
.event-subtitle,
.event-support {
  text-align: center;
}

.img-wrapper {
  width: 500px;
}
.img-logo {
  height: 24px;
}

@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .event-title {
    font-size: 4.2rem;
  }
  .event-subtitle {
    font-size: 1.1rem;
  }
  .event-support {
    font-size: 1.1rem;
  }
}
</style>

<template>
  <div fluid class="div-100">
    <v-sheet color="#2d2d2d">
      <v-container fluid>
        <v-responsive
          :aspect-ratio="16 / 9"
          height="calc(100vh - 105px)"
          width="100%"
        >
          <iframe
            ref="vimeoplayer"
            :src="link"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
            style="width: 100%; height: 100%"
          ></iframe>
        </v-responsive>
      </v-container>
    </v-sheet>   
  </div>
</template>

<script>

export default {
  // name: "Home",
  components: {},
  data: () => ({
    EventItems: [],
    link: "about:blank",
    player: undefined,
  }),
  async beforeCreate() {
    this.EventItems = await this.$store.dispatch("getEventItems");
  },
  async mounted() {
    this.link = (
      await this.$axios.get(
        `/api/webapp/config?clientid=${this.$store.state.client_id}&key=link.vimeo.teaser`
      )
    ).data.value;
  },  
};
</script>

<style lang="scss" scoped>

</style>

<template>
  <v-card flat color="transparent" class="pt-8">
    <!-- 
    <v-card-title :class="center ? 'justify-center' : 'justify-start'">
      <h2 class="component-header" v-html="$t('event.title')"></h2>
    </v-card-title>
    <v-card-subtitle class="black--text" :class="center ? 'justify-center' : 'justify-start'">
      <h2 class="component-header" v-html="$t('event.subtitle')"></h2>
    </v-card-subtitle>
    <v-card-text class="black--text" :class="center ? 'text-center' : ''">
      <p class="mb-1" v-html="$t('event.description.text-1')"></p>
      <p v-html="$t('event.description.text-2')"></p>
    </v-card-text> -->
    
    <v-card-subtitle class="black--text text-center justify-center">
      <h2 v-html="$t('event.title')"></h2>
      <h2 class="mt-2"  v-html="$t('event.subtitle')"></h2>
    </v-card-subtitle>
    <v-card-text class="text-center justify-center">
      <p v-html="$t('event.description.text-1')"></p>
      <p v-html="$t('event.description.text-2')"></p>
      <p v-html="$t('event.description.register')"></p>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    center: Boolean,
  },  
};
</script>

<style lang="scss" scoped>

p{  
  font-size: 1.0rem;
}

::v-deep a {
  padding: 0px;
  margin: 0px;
  text-decoration: none; 
  color: inherit !important;
}

::v-deep a, a:link,
a:visited,
a:hover,
a:active {
  color:inherit !important;
}

</style>

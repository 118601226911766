<template>
  <v-app v-if="$te('page')">
    <v-app-bar color="appshell" app dense flat hide-on-scroll>
      <router-link class="d-flex align-center" to="/">
        <v-img
          class="shrink mr-2"
          contain
          :src="require('_shared/assets/logo.svg')"
          transition="fade-transition"
          :width="$vuetify.breakpoint.mobile ? 96 : 150"
        />
        <v-img
          class="shrink mr-2 mt-1"
          contain
          :src="require('_shared/assets/logo_scope.svg')"
          transition="fade-transition"
          :width="$vuetify.breakpoint.mobile ? 81 : 128"
        />
      </router-link>
      <div />
      <v-spacer></v-spacer>
      <ToolbarUser v-if="$store.getters['account/user']" />
    </v-app-bar>

    <v-main>
      <!-- <v-img
      :src="require('_shared/assets/bg.svg')"
      height="100%"
      style="position:absolute;"
      transition="fade" width="100%"
    ></v-img> -->
      <ConsentView />
      <router-view />
    </v-main>

    <v-footer app color="secondary">
      <FooterView :light="true" :useCredits="false" />
    </v-footer>
  </v-app>
</template>

<script>
/*eslint-disable vue/no-unused-components*/
import ToolbarUser from "_shared/account/components/ToolbarUser";
import FooterView from "_shared/Footer/FooterView.vue";
import ConsentView from "_shared/components/ConsentView";

export default {
  name: "App",
  components: {
    ToolbarUser,
    FooterView,
    ConsentView,
  },
};
</script>

<style lang="scss">
.component-header {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 3rem;
  text-align: left;
  // color:var(--v-primary-base);
}
</style>

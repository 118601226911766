<template>
<v-container>
  <v-card class="py-6" flat color="white" >    
      <v-card-title class="d-flex justify-center text-center">
        <h1
          class="headline-txt text-center"
          v-html="
            $t('home.description.headline') != 'home.description.headline'
              ? $t('home.description.headline')
              : ''
          "
        ></h1>
      </v-card-title>
      <v-card-subtitle class="text-center mt-4">
        <h1 v-html="$t('home.description.subtitle')"></h1>
      </v-card-subtitle>
      <v-card-text class="text" :class="center ? 'text-center' : ''">
        <p class="mb-2" v-html="$t('home.description.text-1')"></p>
        <p v-html="$t('home.description.text-2')"></p>
      </v-card-text>
    
  </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    center: Boolean,
  },
};
</script>

<style scoped lang="scss">
p{
  margin-bottom: 0px; 
}

.headline-txt{
  line-height: 2.4rem;
  /* font-size: 2.3rem; */
  font-weight: normal;
}

.text p{
  font-size: 1.0rem;
  /* line-height: 1.78rem; */
  font-weight: normal;  
}

@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
.headline-txt{
  font-size: 2.0rem;
}

}

</style>
import { render, staticRenderFns } from "./QnAView.vue?vue&type=template&id=52f1d605&"
import script from "./QnAView.vue?vue&type=script&lang=js&"
export * from "./QnAView.vue?vue&type=script&lang=js&"
import style0 from "./QnAView.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../WebApp_Client/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../WebApp_Client/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VDivider,VItem,VItemGroup,VTab,VTabItem,VTabs,VTextarea})


import EventBus from "_shared/EventBus";

export const storeChatRoom = {
    namespaced: true,
    state: () => ({
        chatroom: {},
        messages: [],
        alias: [],
        loading:true
    }),
    getters: {
        GET_Chatroom(state) {
            return state.chatroom;
        },
        GET_Messages(state) {
            return state.messages;
        },
        GET_Alias(state) {
            return state.alias;
        },
        GET_RankedMessages(state) {
            return state.messages
                .slice(0, state.messages.lenght)
                .sort(function (a, b) { return b.message.ranking - a.message.ranking })
                .slice(0, 3).filter(s=>s.message.ranking>0);
        },
        Loading(state)
        {
            return state.loading
        }
    },
    mutations: {
        SET_Chatroom(state, payload) {
            state.chatroom = payload;
        },
        ADD_Message(state, payload) {
            state.messages.push(payload);
            EventBus.$emit('chatroom_newMsg', false, payload.message.applicationUserId);
        },
        SET_Messages(state, payload) {
            state.loading=false;
            state.messages = payload;            
            EventBus.$emit('chatroom_newMsg', true);            
        },
        SET_Alias(state, payload) {
            state.alias = payload;
        },
        UPD_Message(state, payload) {
            var idx = state.messages.findIndex(s => s.message.id === payload.message.id)
            state.messages.splice(idx, 1, payload)
        }
    },
    actions: {
        async getMessages({ commit }, chatRoom) {

            commit('SET_Chatroom', chatRoom);
            commit('SET_Messages', (await window.axios.post('api/chat/getMessages', chatRoom)).data);
        },
        async initAliasUser({ commit }) {
            {
                commit('SET_Alias', (await window.axios.get("/api/Chat/getUserAlias")).data);
            }
        },
    },

}
<template>
  <v-card flat color="transparent">
    <v-card-title :class="center ? 'justify-center' : 'justify-start'">
      <h2 class="component-header">{{ headline }}</h2>
    </v-card-title>
    <v-card-text
      class="d-flex"
      :class="center ? 'justify-center' : 'justify-start'"
    >
      <v-timeline dense align-top class="ml-n7" v-if="AgendaItems && AgendaItems.length > 0">
        <v-timeline-item small v-for="item in AgendaItems" :key="item.id">
          <v-row>
            <v-col cols="auto" class="pt-0">
              <p>{{ getDatetime(item.start) }}</p>
              <p>{{ getTime(item.start, item.end) }}</p>
            </v-col>
            <v-col cols="12" lg="auto" md="auto" class="pt-0">
              <p>{{ item.metaInfo.title }}</p>
              <p>{{ item.metaInfo.description }}</p>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateMixins } from "_shared/helpers";

export default {
  props: {
    headline: String,
    center: Boolean,
    AgendaItems: { required: true },
  },
  mixins: [DateMixins],
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}
</style>
import Vue from 'vue'

export const footer = {
    state: {
        config: undefined
    },
    getters: {
        contact(state) {
            return state.config == undefined ? '' : state.config.find((s) => s.key == "config.contact").value;
        },
        imprint(state) {
            return state.config == undefined ? '' : state.config.find((s) => s.key == "config.imprint").value;
        },
        dataprivacy(state) {
            return state.config == undefined ? '' : state.config.find((s) => s.key == "config.dataprivacy").value;
        }
    },
    mutations: {
        SET_Config(state, payload) {
            state.config = payload;
        }
    },
    actions: {
        async getConfig({ commit }) {
            var list = (await Vue.axios.get('api/webapp/footer')).data;
            commit('SET_Config', list)
        }
    }
}


<template>
  <v-container>
    <p>{{this.$store.state.account.errorMsg}}</p>
  </v-container>
</template>

<script>
export default {
  computed:{
      message()
      {
          var msg=this.$router.currentRoute.params.msg;
          if(msg!=undefined)
          return msg
          else
          return 'Error'
      }
  }  
};
</script>

<style>
</style>
<template>
  <v-card flat color="white" class="div-100">
    <InfoDescription :center="true"/>      
  </v-card>
</template>

<script>
import InfoDescription from './InfoDescription'

export default {
  components: {
    InfoDescription
  },  
};
</script>

<style>
</style>
<template>
  <div
    class="d-flex div-100x"
    v-bind:class="self === true ? 'justify-end' : 'justify-start'"
  >
    <v-card
    shaped
      flat
      class="my-1 d-flex flex-column"
      v-bind:class="self === true ? 'tertiary darken-1' : ''"
    >
      <div class="d-flex">
        <v-card-subtitle
          class="chat-name pb-0"
          v-text="item.username"
        ></v-card-subtitle>
        <v-card-subtitle
          class="chat-date pb-0"
          v-text="formatDate(item.message.timeStamp, false)"
        ></v-card-subtitle>
        <v-badge
          color="transparent"
          title
          overlap
          :content="item.message.ranking"
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            :color="voted ? 'primary' : undefined"
            icon
            class="mt-1 mr-1 ml-auto"
            @click="$emit('vote', item)"
            ><v-icon small>mdi-thumb-up</v-icon></v-btn
          >
        </v-badge>
      </div>
      <v-card-text
        v-text="item.message.text"
        class="pt-2"
        style="white-space: pre-wrap"
      ></v-card-text>
    </v-card>
  </div>
</template>

<script>
import { DateMixins } from "_shared/helpers";
import { mapState } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  mixins: [DateMixins],
  computed: mapState({
    voted()
    {
        return this.item.message.votedUsers.find(s=>s==this.$store.state.account.user.profile.sub);
    },
    self() {
      return (
        this.item.message.applicationUserId ===
          this.$store.state.account.user.profile.sub ||
        this.alias.find(
          (s) => s.userid === this.item.message.applicationUserId
        ) != undefined
      );
    },
    alias(state, getters) {
      return getters[
        `chat-${this.item.eventId}-${this.item.chatRoom}/GET_Alias`
      ];
    },
  }),
};
</script>

<style>
.chat-name {
  font-family: Bold;
  font-weight: bold;
}
.chat-date {
  font-size: 0.75em;
}

.v-badge__badge {
  color: rgb(0, 0, 0) !important;
}
</style>
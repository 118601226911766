<template>
  <div class="d-flex flex-column align-self-start div-100 mt-1">
    <!-- DEBUG Title -->
    <!-- <v-card class="align-start">
      <v-card-subtitle
        v-text="this.$store.state.chat.chatroom.title"
      ></v-card-subtitle>
    </v-card> -->
    <div class="scroller">
      <v-card
        flat
        class="ma-0"
        style="overflow-y: auto"
        height="100%"
        color="tertiary"
        ref="containerList"
        max-height="50vh"
        min-height="128px"
        v-scroll.self="scrollCallback"
      >
        <v-item-group ref="list" class="d-flex flex-column px-1 ma-1">
          <v-item
            v-for="item in this.$store.getters[
              `chat-${this.EventId}-${this.roomName}/GET_Messages`
            ]"
            :key="item.id"
          >
            <ChatMsgItem v-bind:item="item" v-on:vote="voteForMessage" />
          </v-item>
        </v-item-group>
        <div
          class="div-100 my-n3 d-flex align-center justify-center"
          v-if="loading"
        >
          <v-progress-circular
            indeterminate
            size="24" width="3"
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>

      <div style="position: relative">
        <v-btn
          v-if="newMessage"
          x-large
          elevation="3"
          style="position: absolute; bottom: 12px; right: 16px"
          color="primary"
          @click="scollToBottom"
        >
          <v-card-text class="white--text d-flex flex-row"
            ><v-icon color="white" class="mr-2">mdi-arrow-down</v-icon>
            <p>Neue Nachricht</p></v-card-text
          >
        </v-btn>
      </div>
    </div>

    <v-card flat class="mt-1">
      <v-textarea
        ref="textarea"
        class="px-4 pt-4"
        rows="1"
        auto-grow
        label="Nachricht"
        v-model="msg"
        clearable
        append-outer-icon="mdi-send"
        @click:append-outer="sendMessage()"
        v-on:keydown.enter.exact="sendMessage"
        v-on:keyup.enter.alt.exact="addLineBreak()"
      ></v-textarea>
      <v-divider />
    </v-card>
  </div>
</template>

<script>
import "_shared/styles/scroller.scss";
import ChatMsgItem from "./ChatMsgItem";
import EventBus from "_shared/EventBus";
import { storeChatRoom } from "./store";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    roomName: { type: String, required: true },
    isOperator: { type: Boolean, required: false },
    selectedAlias: { type: Object, value: undefined },
  },
  components: {
    ChatMsgItem,
  },
  data: () => ({
    msg: "",
    newMessage: false,    
  }),
  methods: {
    scrollCallback() {
      this.newMessage = false;
    },
    scollToBottom() {
      this.newMessage = false;
      this.$nextTick(() => {
        var target =
          this.$refs.list.$children[
            this.$store.getters[
              `chat-${this.EventId}-${this.roomName}/GET_Messages`
            ].length - 1
          ];
        if (target != null)
          this.$vuetify.goTo(target, {
            container: this.$refs.containerList,
            duration: 800,
            easing: "easeInOutCubic",
          });
      });
    },
    addLineBreak() {
      this.msg += "\n";
    },
    reset() {
      this.msg = "";
      this.$refs.textarea.blur();
    },
    sendMessage() {
      this.msg = this.msg.replace(/^\s+|\s+$/g, "");

      if (this.msg.trim().length > 0) {
        this.$chathub.sendMessage(
          this.msg,
          this.roomName,
          this.selectedAlias?.userid,
          this.EventId
        );
      }
      setTimeout(() => {
        this.reset();
      }, 150);
    },
    registerStore() {
      if (this.EventId != null) {
        if (!this.$store.hasModule(`chat-${this.EventId}-${this.roomName}`)) {
          this.$store.registerModule(
            `chat-${this.EventId}-${this.roomName}`,
            storeChatRoom
          );

          if (this.isOperator)
            this.$store.dispatch(
              `chat-${this.EventId}-${this.roomName}/initAliasUser`
            );
        }
        this.$chathub.JoinChatRoom(this.roomName, this.EventId);
      }
    },
    voteForMessage(item) {
      this.$chathub.voteForMessage(
        this.$store.state.account.user.profile.sub,
        item.message.id,
        item.message.votedUsers.find(
          (s) => s == this.$store.state.account.user.profile.sub
        )
          ? false
          : true,
        this.chatRoom
      );
    },
  },
  async mounted() {
    await window.chathub.start();
    this.registerStore();
  },
  created() {
    EventBus.$on("chatroom_init", async () => {
      this.registerStore();
    });
    EventBus.$on("chatroom_newMsg", (scroll, userid) => {
      if (
        scroll ||
        userid == this.$store.state.account.user.profile.sub ||
        userid == this.selectedAlias?.userid
      )
        this.scollToBottom();
      else if (
        document.documentElement.clientHeight / 2 <=
        this.$refs.containerList.$el.clientHeight
      )
        this.newMessage = true;
    });
  },
  async beforeDestroy() {
    await window.chathub.stop();
    this.$store.commit("SET_EventId", null);

    EventBus.$off("chatroom_init");
    EventBus.$off("chatroom_newMsg");
  },
  computed: {
    ...mapGetters(["EventId"]),
    ...mapState({
      chatRoom(state, getters) {
        return getters[`chat-${this.EventId}-${this.roomName}/GET_Chatroom`];
      },
      loading(state, getters)
      {
        return getters[`chat-${this.EventId}-${this.roomName}/Loading`];
      }
    }),
  },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 0px;
}
</style>
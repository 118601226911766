<template>
  <v-dialog v-model="dialog1" min-width="50vw" max-width="768">
    <v-card class="d-flex flex-column" color="tertiary">
      <v-responsive :aspect-ratio="16 / 9">
        <!-- <iframe
          ref="vimeoplayer"
          :src="link"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
          style="width: 100%; height: 100%"
          class="d-flex"
        ></iframe> -->
        <div
          class="div-100"
          ref="vimeoplayer"          
        ></div>
      </v-responsive>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import Player from "@vimeo/player";

export default {
  computed: mapState({
    roomState(state, getters) {
      return getters["notification/roomState"];
    },
  }),
  props: {
    dialog: Boolean,
    link: { type: String, required: true },
  },
  data: () => ({
    player: undefined,
    dialog1: false,
  }),
  watch: {
    dialog: {
      handler(val) {
        this.dialog1 = val;
        if (!val) this.player?.pause();
        else {
          this.$nextTick(() => {
            if (this.player == undefined) {
              var options = {
                url: this.link,
                responsive: true,
              };
              this.player = new Player(this.$refs.vimeoplayer, options);
              this.player.on("loaded", () => {
                this.player.play();
              });
            } else this.player.play();
          });
        }
      },
    },
    dialog1: {
      handler(val) {
        this.$emit("update:dialog", val);
      },
    },
  },
};
</script>

<style scoped>
</style>
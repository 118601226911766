<template>
  <div
    class="div-100 d-flex align-center"
    :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
  >
    <Credits v-if="useCredits" />

    <v-row v-else no-gutters class="justify-end">
      <v-col cols="12" class="d-flex justify-center align-center">
        <Links />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*eslint-disable vue/no-unused-components*/
import Links from "./components/Links";
import Credits from "./Credits";

export default {
  components: {
    Links,
    Credits,
  },
  props: {
    light: Boolean,
    useCredits: Boolean,
  },
};
</script>


<style scoped lang="scss">
::v-deep a {
  padding: 0px;
  margin: 0px;
  text-decoration: none;
}

::v-deep a, a:link,
a:visited,
a:hover,
a:active {
  color:inherit;
}

::v-deep .txt-light {
  color: var(--v-footer-base);
}
::v-deep .txt-dark {
  color: var(--v-footerInvers-base);
}
</style>

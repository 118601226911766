<template>
  <div class="v-toolbar__content">
    <div class="mr-2 d-flex align-center black--text" v-if="!$vuetify.breakpoint.smAndDown">      
      <v-card-text
        v-if="this.$store.getters['account/username']"
        v-text="this.$store.getters['account/username']"
      ></v-card-text>
    </div>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn light icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-card flat>
        <v-card flat v-if="$vuetify.breakpoint.smAndDown">
          <v-card-text            
            v-if="this.$store.getters['account/username']"
            v-text="this.$store.getters['account/username']"
          ></v-card-text>   
             
        </v-card>
        <v-item-group>
          <!-- <v-list-item
            @click="account"
            v-if="this.$store.state.client_id!='account.manager'&& this.$store.getters['account/user']"
            >Account Manager</v-list-item
          > -->
          <v-list-item @click="operator" v-if="hasRole('manager') && this.$store.state.client_id!='operator.client'"
            >Manager</v-list-item
          >
          <v-list-item
            @click="login"
            v-if="!this.$store.getters['account/user']"
            >Login</v-list-item
          >
          <v-list-item
            @click="logout"
            v-if="this.$store.getters['account/user']"
            >Logout</v-list-item
          >
        </v-item-group>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {  
  methods: {        
    login() {
      this.$account.login();
    },
    logout() {
      this.$account.logout();
    },
    register() {
      window.open(process.env.VUE_APP_ID4Server);
      //this.$router.push({ name: "Register" });
    },
    async operator() {
      window.open(
        (
          await this.$axios.get("/authenticate/operatorClient", {
            withCredentials: true,
          })
        ).data.url
      );
    },
    account() {
      this.$axios
        .get("/authenticate/accountmanager", { withCredentials: true })
        .then(function (response) {
          window.open(response.data.url);
        })
        .catch(function (error) {
          console.log("err" + error);
        });
    },
  },
};
</script>

<style>
</style>
/*eslint-disable no-unused-vars*/
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import vuetify from './plugins/vuetify';
import vuetify from '_shared/styles/vuetify';

import _i18n, { i18n } from '_shared/i18n'
import account, { mgr } from '_shared/account'

import notification from '_shared/notification'
import userwatcher from '_shared/userWatcher'

import chat from '_shared/ChatRoom'

import '_shared/styles/main.css'
import '@mdi/font/css/materialdesignicons.css'


Vue.config.productionTip = false

Vue.use(_i18n, store)
Vue.use(notification, store)

Vue.use(account, {
  store, router, mgr: {
    client_id: "webapp.client",
    scope: "openid profile api role",
  }
})

Vue.use(userwatcher, store)
Vue.use(chat, store)

const GlobalFunctions = {
  install(Vue) {
    Vue.StartAllHubs = async function () {
      try {
        await window.notification.start();
        await window.userwatcher.start();        
      } catch (e) {
        console.log(e);
      }
    }
  }
};

Vue.use(GlobalFunctions)
window.account.init();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')













<template>
  <v-card flat class="ma-1">
    <div class="d-flex">
      <v-card-subtitle class="qna-name">{{ item.username }}</v-card-subtitle>
      <v-card-subtitle class="qna-date" v-text="formatDate(item.question.timeStamp)"></v-card-subtitle>
    </div>
    <div class="d-flex flex-row align-center">
      <v-card-text style="white-space: pre-wrap">{{ item.question.question }}</v-card-text>
      <v-icon color="success" v-if="item.question.isAnswered">mdi-check</v-icon>
      <v-card-text
        v-text="item.question.ranking"
        style="width: auto"
      ></v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!item.question.isPublic || item.question.isAnswered"
          icon
          color="primary"
          @click="likeQuestion"
        >
          <v-icon>mdi-thumb-up</v-icon>
        </v-btn>
      </v-card-actions>
    </div>
    <div>
      <v-card-text v-if="!item.question.isPublic">
        Frage muss freigegeben werden
        <v-progress-linear height="2" indeterminate></v-progress-linear>
      </v-card-text>
    </div>
    <v-divider/>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  
  props: {
    item: {},
  },
  methods: {
    likeQuestion() {
      this.$axios.post("api/qna/likeQuestions", { id: this.item.question.id });
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date).format("D.M H:mm");
    },
  },
};
</script>

<style>
.qna-name{
  font-family: Bold;
}
.qna-date{
  font-size: 0.75em;
}
</style>
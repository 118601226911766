<template>
  <!-- <div
    class="div-100 d-flex flex-column align-center justify-center text-center"
  >
    <v-card-subtitle>
      <h1 v-if="roomState === 'done'" v-html="$t('waitingroom.closed')"></h1>
      <h1
        v-else-if="roomState === 'pause'"
        v-html="$t('waitingroom.pause')"
      ></h1>
      <h1 v-else-if="roomState === 'upcoming'"v-html="$t('waitingroom.title')"></h1>
      <h1 v-else-if="roomState === 'live'">Live</h1>
    </v-card-subtitle>
  </div> -->
  <div class="div-100" v-if="roomState === 'upcoming'">
    <Teaser />    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Teaser from "../components/Teaser.vue";

export default {
  components: {
    Teaser,
  },
  computed: {
    ...mapGetters("notification", ["roomState"]),
  },
  watch: {
    roomState: {
      handler(value) {
        if (value === "live") {
          this.$router.replace({
            name: "Live",
          });
        }
      },
    },
  },
};
</script>

<style scoped>
h1 {
  line-height: 1.35em;
}
</style>